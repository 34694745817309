import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const navItems = [
  { title: "Posts", url: "/" },
  { title: "Ask", url: "/ask" },
  { title: "About", url: "/about" },
  { title: "Subscribe", url: "/subscribe" },
]

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const header = (
      <h1
        style={{
          ...scale(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )

    const nav = (
      <nav
        style={{
          marginBottom: rhythm(1.5),
        }}
      >
        {navItems.map(({ title, url }, index) => (
          <React.Fragment key={url}>
            {location.pathname === url ? title : <Link to={url}>{title}</Link>}
            {index < navItems.length - 1 && (
              <span style={{ margin: rhythm(0.25) }}>&#8226;</span>
            )}
          </React.Fragment>
        ))}
      </nav>
    )

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        {nav}
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    )
  }
}

export default Layout
